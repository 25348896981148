<template>
    <div class="profileOuterBox">
		<div class="fence">
			<div class="verticalLine"></div>
			<red-block class="redBlock"></red-block>
		</div>
		<div class="personCardBox">
			<div class="personCard">
				<div class="nameBox">
					<span class="personName">{{dataInput.nameCn}}</span>
					<img :src="genderIcon" class="gender" alt="">
				</div>
				<div class="infoRow" style="margin-bottom: 1.28vh; cursor: pointer;">
					<span class="itemValue">{{address}}&ensp;
						<img src="@/assets/common/phone.png" alt="" style="height: 1.3vh; aspect-ratio: 1/1; margin-right: 0.1vw">
					</span>
					<span v-show="!showPhone" class="itemValue" @click="switchVisibility">展开</span>
					<span class="itemValue" v-show="showPhone" @click="switchVisibility">{{dataInput.phone || '-'}}</span>
				</div>
				<div class="avatarBox">
					<img class="avatar" :src="dataInput.face" alt="" v-if="dataInput.face">
				</div>
			</div>
			<div class="triangleCorner" style="top: 0; left: 0; border-color: #ffd03b transparent transparent #ffd03b"></div>
			<div class="triangleCorner" style="top: 0; right: 0; border-color: #ffd03b #ffd03b transparent transparent"></div>
			<div class="triangleCorner" style="bottom: 0; left: 0; border-color: transparent transparent #ffd03b #ffd03b"></div>
			<div class="triangleCorner" style="bottom: 0; right: 0; border-color: transparent #ffd03b #ffd03b transparent"></div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.profileOuterBox {
	width: 15.99vw;
	display: flex;
	justify-content: space-between;
	.fence {
		width: 8.64%;
		height: 8.34vh;
		position: relative;
		.redBlock {
			position: absolute;
			left: 9%;
			width: 1.11vh;
			aspect-ratio: 1/1;
		}
		.verticalLine {
			position: absolute;
			bottom: 0;
			left: 0.6vh;
			height: 100%;
			width: 0;
			border: #ffd03b solid;
			border-width: 0 0 0 0.3px;
			margin: auto;
		}
	}

	.personCardBox {
		width: 15.99vw;
		height: 6.85vh;
		position: relative;
		border: #ffd03b 1px solid;
		display: flex;
		align-items: center;
		justify-content: center;

		.personCard {
			width: 89.74%;
			height: 81.61%;
			position: relative;

			.nameBox {
				width: 80%;
				height: 2.22vh;
				display: flex;
				align-items: center;

				.personName {
					line-height: 2.22vh;
					font-size: 1.48vh;
					color: #fff;
					font-family: 'pingfangM', sans-serif;
					font-weight: 500;
				}
				.gender {
					height: 1.85vh;
					aspect-ratio: 1/1;
					margin-left: 1.42%;
				}
			}
			.infoRow {
				height: 2.037vh;
				width: 100%;
				position: relative;
				margin-top: 0.37vh;

				.itemValue {
					
					line-height: 2.037vh;
					font-size: 1.3vh;
					font-family: 'pingfangSc', sans-serif;
					font-weight: 400;
					color: #fff;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.avatarBox {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				aspect-ratio: 1/1;

				.avatar {
					width: 100%;
					height: 100%
				}
			}

		}

		.triangleCorner {
			position: absolute;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0.37vh;
		}
		
	}
}
</style>

<script>
const redBlock = () => import('./redBlock.vue');
export default {
	props:{
		dataInput:{
			type: Object
		}
	},
	components:{
		redBlock
	},
	data(){
		return {
			genderIcon: '',
			age: 0,
			address: '',
			showPhone: false,
			siv: null
		}
	},
	mounted(){
		this.initData(this.dataInput);
		return false;
	},
	methods: {
		initData(e){
			let {birthday, sex, groupId, building, roomNumber} = e;
			if(sex == '女'){
				this.genderIcon = require('@/assets/page5/female.png');
			}else{
				this.genderIcon = require('@/assets/page5/male.png');
			}
			this.age = this.$u.getAge(birthday);
			groupId = groupId || '';
			building = building || '';
			roomNumber = roomNumber || '';
			let buildingNumber = this.$u.parseChineseNumber(building);
			if(buildingNumber){
				buildingNumber += '栋 ';
			}
			this.address = buildingNumber + groupId + '单元 ' + roomNumber + '号';
			return false;
		},
		switchVisibility(){
			this.showPhone = !this.showPhone;
			if(this.showPhone){
				this.siv = setInterval(() => {
					this.showPhone = false;
					clearInterval(this.siv);
				}, 12000)
			}else{
				clearInterval(this.siv);
			}
		}
	},
	watch: {
		dataInput(e){
			this.initData(e);
		}
	}
}
</script>

